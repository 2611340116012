<template>
  <v-container id="user-profile-view" fluid tag="section">
    <v-row justify="center">
      <v-col cols="12" md="8">
        <material-card color="green" icon="mdi-account-outline">
          <template #title>
            Learner —
            <small class="text-body-1">Complete Details of Learner</small>
          </template>
          <template #subtitle> Required fields (*) </template>

          <v-form>
            <v-container class="py-0">
              <v-row>
                <v-spacer></v-spacer>
                <v-col cols="6" md="4">
                  <v-icon color="orange" v-if=form.disabled >mdi-hand-back-right</v-icon>
                  <v-text-field
                    :color="lrncolor"
                    label="LRN*"
                    placeholder="LRN*"
                    v-model="form.lrn"
                    :rules="[rules.required, rules.minimum, verifyLRN]"
                    hint="Required"
                    required
                    type="number"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="3">
                  <v-text-field
                    color="purple"
                    label="First Name"
                    v-model="form.gname"
                  />
                </v-col>

                <v-col cols="12" md="3">
                  <v-text-field
                    color="purple"
                    label="Last Name"
                    v-model="form.surname"
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    color="purple"
                    label="Middle Name"
                    v-model="form.mname"
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    color="purple"
                    label="Suffix Name"
                    v-model="form.sfxname"
                  />
                </v-col>

                <v-col cols="12" md="3">
                  <!-- <v-text-field color="purple" label="Gender" /> -->
                  <v-autocomplete
                    color="purple"
                    label="Gender"
                    :items="gender"
                    v-model="form.gender"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    color="purple"
                    label="Date of Birth"
                    type="date"
                    v-model="form.dob"
                    @change="getAge()"
                    @click="getAge()"
                    @blur="getAge()"
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    color="purple"
                    label="Age"
                    type="number"
                    v-model="form.age"
                    readonly
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    color="purple"
                    label="Contact"
                    type="number"
                    v-model="form.contact"
                  />
                </v-col>
                <v-col cols="6">
                  <v-autocomplete
                    label="Barangay"
                    clearable
                    item-value="id"
                    item-text="name"
                    :items="brgy"
                    placeholder="Barangay"
                    v-model="form.brgy_id"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    label="Other Address"
                    clearable
                    placeholder="Other Address"
                    v-model="form.address_others"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="8">
                  <v-text-field
                    color="purple"
                    label="Emergecy Contact Person"
                    v-model="form.emrgcyContactName"
                  />
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    color="purple"
                    type="number"
                    label="Emergency Contact No."
                    v-model="form.emrgcyContactNo"
                  />
                </v-col>
                <!-- <v-col cols="12">
                  <v-textarea color="purple" label="Remarks" value="" />
                </v-col> -->
              </v-row>
              <v-row>
                <v-col cols="12" md="4">
                  <v-switch label="LSEN" v-model="form.disabled"></v-switch>
                </v-col>
                <v-col v-if="form.disabled">
                  <v-text-field v-model="form.disabled_info" label="Specify LSEN"></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="text-left">
                  <v-btn color="primary" min-width="150" @click="submit">
                    {{ btnsubmit }}
                  </v-btn>
                </v-col>
                <v-col class="text-right">
                  <v-btn text to="/learners">Cancel</v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </material-card>
      </v-col>
    </v-row>
    <v-row v-if="selLearner">
      <v-col>
        <learnersrecord :learnerid="selLearner.id" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { sync, get, dispatch } from "vuex-pathify";
import learnersrecord from "./learnersrecord.vue";
import moment from "moment";

export default {
  name: "LearnerForm",
  data() {
    return {
      gender: ["MALE", "FEMALE"],
      lrncolor: "purple",
      lrnplaceholder: "",
      btnsubmit: "Add Learner",
      form: {
        lrn: "",
        gname: "",
        surname: "",
        mname: "",
        age: "",
        gender: "",
        dob: "",
        contact: "",
        brgy_id: "",
        address_others: "",
        emrgcyContactName: "",
        emrgcyContactNo: "",
        disabled_info:"",
        method: "POST",
      },
      rules: {
        required: (v) => !!v || "this field is required",
        minimum: (v) =>
          (v && v.length == 12) || "LRN must be 12 Numerical Characters",
      },
    };
  },
  computed: {
    ...get("brgy", ["brgy"]),
    ...sync("learners", ["newlearners", "selLearner", "checkLRN"]),
    ...sync("brgy", ["brgy"]),
  },

  components: {
    learnersrecord,
  },

  beforeMount() {
    this.NeworUpdate();
    this.loadBrgy();
  },

  methods: {
    async submit() {
      if (this.selLearner) {
        this.selLearner = this.form;
        await dispatch("learners/update", this.form);
        this.$toast.success("Successfull Update");
      } else {
        await dispatch("learners/submit", this.form);
        this.$toast.success("Successfully Added");
      }
      this.$router.push({ name: "Learners" });
    },

    loadBrgy() {
      dispatch("brgy/brgyload");
    },

    NeworUpdate() {
      if (this.selLearner) {
        this.btnsubmit = "Update Learner";
        this.form = this.selLearner;
        this.form.method = "PUT";
      } else {
        this.btnsubmit = "Add Learner";
      }
    },

    getAge() {
      let c_dob = moment(this.form.dob);

      let c_dob_mnth = c_dob.month();
      let c_dob_year = c_dob.year();

      let now = moment();
      let now_mnth = now.month();
      let now_year = now.year();

      let diff_year_month = (now_year - c_dob_year) * 12;
      let diff_month = now_mnth - c_dob_mnth;
      let diff = (diff_year_month + diff_month) / 12;

      this.form.age = Math.floor(diff);
    },

    verifyLRN: async function () {
      await dispatch("learners/verifyLRN", this.form.lrn);
      if (this.checkLRN) {
        this.$toast.error("LRN Already Exist");
      }
    },
  },
};
</script>
