<template>
    <v-card>
        <v-card-title>
            Learners Record
        </v-card-title>
        <v-card-text>
            <v-data-table 
      :headers="headers"
      :items="learnerAssessment"
      :items-per-page="10"
      :group-by="['assessment.assessment_tool']"
      class="elevation-0"
      dense
    ></v-data-table>
        </v-card-text>
    </v-card>
    
  </template>
  <script>
  import { sync,dispatch } from "vuex-pathify";

  export default {
    props:{
      learnerid:Number
    },
    data () {
      return {
        headers: [
        { text: 'Program', value: 'assessment.program.name' },
          { text: 'School', value: 'assessment.school.name' },
          {
            text: 'Grade Level',
            align: 'start',
            sortable: false,
            value: 'gradeLvl',
          },
          { text: 'Assessment Tool', value: 'assessment.assessment_tool' },
          { text: 'Assessment Status', value: 'assessment._status' },
          { text: 'Assessment Date', value: 'assessment._date' },
          { text: 'Subject', value: 'type' },
          { text: 'Pre Test', value: '_level' },
          { text: 'Post Test', value: 'post_level' },
        ],
        
      }
    },

    computed: {
    ...sync('learners',['learnerAssessment','selLearner']),
    },

    beforeMount(){
      this.getLearnersAssessment()
    },

    methods:{
      async getLearnersAssessment(){
        dispatch('learners/getLearnersAssessmentRecord',this.selLearner.id)
      }
    }
  }
</script>